/* App.css */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
}

.content {
    flex: 1; /* This makes the content area take up available space */
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    position: relative;
    width: 100%;
    bottom: 0;
}

.footer__container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.footer__info {
    font-size: 0.875rem;
}

.footer__socials a {
    color: #fff;
    font-size: 1.5rem;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.footer__socials a:hover {
    color: #007bff;
}

@media (max-width: 768px) {
    .footer__container {
        flex-direction: column;
        text-align: center;
    }

    .footer__socials {
        margin-top: 10px;
    }
}
