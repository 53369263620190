/* Dashboard.css */

/* General Styles */
.dashboard-page {
    font-family: Arial, sans-serif;
}

.container {
    padding: 0 15px;
}

/* Table Styles */
.dashboard-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.dashboard-table th, .dashboard-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.dashboard-table th {
    background-color: #f2f2f2;
}

/* Button Styles */
.btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.btn-primary:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .dashboard-table th, .dashboard-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
    
    .dashboard-table th {
        display: none; /* Hide table headers */
    }
    
    .dashboard-table tr {
        margin-bottom: 10px;
        border-bottom: 2px solid #ddd;
        display: block;
    }

    .dashboard-table td {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        position: relative;
        padding-left: 50%;
        text-align: left;
    }
    
    .dashboard-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        text-align: left;
    }

    .btn-primary {
        display: block;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 0 10px;
    }
}
