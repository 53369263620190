.carousel {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.carousel-images {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel img {
    width: 100%; /* Ensure each image takes the full width */
    height: calc(100% - 120px);
    object-fit: cover;
    flex-shrink: 0; /* Prevent images from shrinking */
}

button {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

/* Media queries remain unchanged */

@media (max-width: 1293px){
    .carousel img{
        width: 100%;
        height: 500px;
    }
}

@media (max-width: 870px){
    .carousel img{
        height: 400px;
        width: 100%;
        top: 160px;
        object-fit: auto;
    }
}

@media (max-width: 675px){
    .carousel img{
        height: 250px;
        width: 100%;
        top: 260px;
    }
}