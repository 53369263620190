/* DataEntry.css */

.data-entry-page {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    color: #333;
}

/* .container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
} */

.page-title {
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    margin-top: 2em;
    font-size: 2.5rem;
    font-weight: bold;
}

.table {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
}

.table th, .table td {
    padding: 15px;
    vertical-align: middle;
    border-top: 1px solid #ddd;
}

.table input {
    width: 100%;
}

.btn {
    margin-right: 10px;
}

.btn-danger {
    background-color: #dc3545;
    border: none;
}

.btn-danger:hover {
    background-color: #c82333;
}

.btn-primary {
    background-color: #007bff;
    border: none;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn-success {
    background-color: #28a745;
    border: none;
}

.btn-success:hover {
    background-color: #218838;
}

/* DataEntry.css */
.data-entry-page {
    padding: 20px;
}

.page-title {
    text-align: center;
    margin-bottom: 20px;
}

/* Ensure the form and table are centered and responsive */
/* .container {
    max-width: 100%;
} */

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 10px;
    text-align: left;
}

.table input, .table select {
    width: 100%;
    box-sizing: border-box;
}

.table th {
    background-color: #f8f9fa;
}

.btn {
    margin: 5px;
}

/* Responsive design */
@media (max-width: 768px) {
    .table th, .table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    .table thead {
        display: none;
    }

    .table tr {
        margin-bottom: 15px;
        display: block;
        border: 1px solid #ddd;
    }

    .table td {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .table td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 10px;
    }
}

/* DataEntry.css */

.data-entry-page {
    padding: 20px;
}

.page-title {
    text-align: center;
    margin-bottom: 20px;
}

/* Ensure the form and table are centered and responsive */
/* .container {
    max-width: 100%;
} */

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 10px;
    text-align: left;
}

.table input, .table select {
    width: 100%;
    box-sizing: border-box;
}

.table th {
    background-color: #f8f9fa;
}

/* Responsive design */
@media (max-width: 768px) {
    .table th, .table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    .table thead {
        display: none;
    }

    .table tr {
        margin-bottom: 15px;
        display: block;
        border: 1px solid #ddd;
    }

    .table td {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .table td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 10px;
    }
}

/* Button styles */
.btn {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-primary {
    background: linear-gradient(45deg, #007bff, #0056b3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
    background: linear-gradient(45deg, #0056b3, #003d7a);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.btn-success {
    background: linear-gradient(45deg, #28a745, #218838);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-success:hover {
    background: linear-gradient(45deg, #218838, #1e7e34);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.btn-info {
    background: linear-gradient(45deg, #17a2b8, #117a8b);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-info:hover {
    background: linear-gradient(45deg, #d7f5fa, #0b5b6e);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 15em;
}
/* DataEntry.css */

.alert {
    padding: 15px;
    background-color: #4CAF50; /* Green */
    color: white;
    border-radius: 5px;
    margin-top: 15px;
}
