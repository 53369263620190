/* ContactUs.css */

.contact-us-page {
    padding: 20px;
}

/* .container {
    max-width: 800px;
    margin: 0 auto;
} */

.text-center {
    text-align: center;
    margin-top: 1.5em;
}

.contact-form-container {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.btn-primary {
    background: linear-gradient(45deg, #007bff, #0056b3);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    background: linear-gradient(45deg, #0056b3, #003d7a);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
/* src/pages/Product.css */
/* src/pages/Product.css */
.product-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .product-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .product-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .product-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 350px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .product-card-icon {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .product-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .product-card p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .product-card .btn {
    display: inline-block;
    background: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .product-card .btn:hover {
    background: #0056b3;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Additional CSS for other sections */
  
  /* ContactUs.css */

/* Confirmation Message Styles */
.confirmation-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    text-align: center;
}
