/* src/pages/Product.css */
.product-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .product-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .product-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .product-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 350px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .product-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .product-card p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .product-card .btn {
    display: inline-block;
    background: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .product-card .btn:hover {
    background: #0056b3;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .package-details {
    text-align: center;
    margin: 40px 0;
  }
  
  .package-details h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .package-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .package-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .package-card ul li {
    margin-bottom: 10px;
  }
  
  .package-cost {
    font-size: 1.2rem;
    margin: 20px 0;
    font-weight: bold;
  }
  
  .package-card .btn {
    display: inline-block;
    background: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .package-card .btn:hover {
    background: #0056b3;
  }
  